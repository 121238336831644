<template>
  <div>
    <v-dialog v-model="showEditDialog" width="1100" scrollable persistent>
      <v-card>
        <v-card-title style="font-size: 20px; height: 70px">
          <p class="mb-0">
            <v-icon style="font-size: 28px; color: #424242"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("editcertificate") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="CancelEditDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 700px">
          <v-row no-gutters class="pt-1">
            <!-- Left part -->
            <v-col cols="12" sm="4" style="border-right: 2px solid #0000001f">
              <div class="pr-2 sticky-sidebar">
                <v-list class="mt-3">
                  <draggable
                    v-model="Fields"
                    :options="{ group: 'items' }"
                    :sort="false"
                  >
                    <v-list-item
                      class="v-list-btn-bg"
                      draggable="true"
                      v-for="(item, i) in Fields"
                      :key="i"
                      @dragstart="startDrag(item)"
                      :disabled="item.disabled"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t(item.text)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <img :src="item.icon" :width="item.width" alt="" />
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
                <v-divider class="my-5"></v-divider>
                <div class="flex-container" v-show="droppedTextField">
                  <p style="font-size: 18px; color: #8e8e8e; margin-bottom: 0">
                    {{ $t("text") }}
                  </p>
                  <v-row no-gutters class="flex-item">
                    <v-col>
                      <v-combobox
                        v-model="selectedFontStyle"
                        class="mt-3"
                        :items="fontStylesList"
                        :label="$t('font-style')"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="flex-item">
                    <v-col cols="12" md="5">
                      <v-combobox
                        v-model="selectedFontSize"
                        :items="fontSizesList"
                        :label="$t('just-size')"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-combobox>
                    </v-col>
                    <v-col
                      cols="12"
                      md="7"
                      style="display: flex; justify-content: end"
                    >
                      <v-btn-toggle
                        dense
                        class="ml-1"
                        v-model="fontBoldItalicUnderline"
                        multiple
                      >
                        <v-btn>
                          <v-icon>mdi-format-bold</v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon>mdi-format-italic</v-icon>
                        </v-btn>

                        <v-btn>
                          <v-icon>mdi-format-underline</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="flex-item">
                    <v-col>
                      <v-menu
                        bottom
                        offset-y
                        min-width="auto"
                        v-model="colorMenu"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            block
                            :color="selectedFontColor"
                            height="40"
                            v-bind="attrs"
                            v-on="on"
                          ></v-btn>
                        </template>

                        <v-color-picker
                          v-model="selectedFontColor"
                          flat
                          dot-size="30"
                          min-width="341"
                        ></v-color-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="flex-item">
                    <v-col>
                      <v-btn
                        color="#6FDC42"
                        class="text-capitalize white--text"
                        block
                        @click="saveEditedText()"
                        >{{ $t("save") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- <v-row no-gutters>
                <v-col class="d-flex justify-center items-center">
                  <v-btn @click="captureAsImage" width="100px">Save Img</v-btn>
                </v-col>
                <v-col class="d-flex justify-center items-center">
                  <v-btn @click="captureAsPDF" width="100px">Save PDF</v-btn>
                </v-col>
              </v-row> -->
            </v-col>
            <!-- Right part -->
            <v-col cols="12" sm="8" class="pl-2 pt-3">
              <div class="img-container">
                <div
                  id="pdf-container"
                  class="responsive-img"
                  @drop="handleDrop()"
                  @dragover.prevent="dragMovement($event)"
                  @dragenter.prevent
                >
                  <img
                    ref="certificateImg"
                    :src="CertificateTemp"
                    style="display: block; width: 100%; height: 100%"
                    @load="onImageLoad"
                    alt=""
                  />
                  <div
                    style="
                      position: absolute;
                      inset: 0;
                      box-sizing: content-box;
                      width: 100%;
                      height: 100%;
                    "
                    ref="imgContainer"
                  >
                    <vue-draggable-resizable
                      v-for="(element, index) in elements"
                      :key="element.id"
                      :x="element.x"
                      :y="element.y"
                      :w="element.width"
                      :h="element.height"
                      :active="element.active"
                      :prevent-deactivation="element.prevent_autoDeactivate"
                      :resizable="true"
                      :draggable="true"
                      :handles="['tl', 'tr', 'br', 'bl']"
                      @dragging="onDragging"
                      @dragstop="onDragstop"
                      @resizing="onResizing"
                      @resizestop="onResizstop"
                      @activated="onActivated(element)"
                      @deactivated="onDeactivated(element)"
                      :parent="true"
                    >
                      <div
                        :style="getElementStyles(element)"
                        class="element-content"
                      >
                        <div
                          class="tooltip-text-element"
                          v-if="
                            element.type != 'date' &&
                            element.type != 'stamp_Img'
                          "
                          @mouseover="showTooltip(element)"
                          @mouseleave="hideTooltip(element)"
                        >
                          <div
                            v-if="element.type === 'course-name'"
                            :contenteditable="false"
                            class="text-element"
                            @dblclick="dblClick(element)"
                            :style="[
                              {
                                'font-family': element.fontStyle,
                                'font-size': element.fontSize + 'px',
                                color: element.fontColor,
                                'font-weight':
                                  element.fontBoldItalicUnderline?.includes(0)
                                    ? 'bold'
                                    : 'normal',
                                'font-style':
                                  element.fontBoldItalicUnderline?.includes(1)
                                    ? 'italic'
                                    : 'normal',
                                'text-decoration':
                                  element.fontBoldItalicUnderline?.includes(2)
                                    ? 'underline'
                                    : 'none',
                              },
                            ]"
                          >
                            {{ element.showText }}
                          </div>
                          <!-- :value="element.showText"  @input="updateText($event, element)"-->
                          <div
                            v-else
                            :contenteditable="element.editing"
                            class="text-element"
                            @dblclick="dblClick(element)"
                            @blur="updateItem(index, $event)"
                            @input="limitTextLength($event, element.type)"
                            :style="[
                              {
                                'font-family': element.fontStyle,
                                'font-size': element.fontSize + 'px',
                                color: element.fontColor,
                                'font-weight':
                                  element.fontBoldItalicUnderline?.includes(0)
                                    ? 'bold'
                                    : 'normal',
                                'font-style':
                                  element.fontBoldItalicUnderline?.includes(1)
                                    ? 'italic'
                                    : 'normal',
                                'text-decoration':
                                  element.fontBoldItalicUnderline?.includes(2)
                                    ? 'underline'
                                    : 'none',
                              },
                            ]"
                          >
                            {{
                              element.showText === "enter-text"
                                ? $t(element.showText)
                                : element.showText
                            }}
                          </div>
                          <div v-if="element.showTooltip" class="tooltip">
                            {{ $t("click-drag-edit") }}
                          </div>
                        </div>

                        <div
                          v-if="element.type === 'date'"
                          class="tooltip-text-element"
                          @mouseover="showTooltip(element)"
                          @mouseleave="hideTooltip(element)"
                        >
                          <div
                            :contenteditable="false"
                            class="text-element"
                            :style="[
                              {
                                'font-family': element.fontStyle,
                                'font-size': element.fontSize + 'px',
                                color: element.fontColor,
                                'font-weight':
                                  element.fontBoldItalicUnderline.includes(0)
                                    ? 'bold'
                                    : 'normal',
                                'font-style':
                                  element.fontBoldItalicUnderline.includes(1)
                                    ? 'italic'
                                    : 'normal',
                                'text-decoration':
                                  element.fontBoldItalicUnderline.includes(2)
                                    ? 'underline'
                                    : 'none',
                              },
                            ]"
                            @dblclick="dblClick(element)"
                          >
                            {{ element.showText }}
                          </div>
                          <div v-if="element.showTooltip" class="tooltip">
                            {{ $t("click-drag-edit") }}
                          </div>
                        </div>

                        <img
                          v-if="element.type === 'stamp_Img'"
                          :src="element.imgUrl"
                          class="image-element"
                          alt=""
                        />
                        <v-icon
                          v-if="element.active"
                          @click.stop="removeElement(element.id)"
                          class="mdi-close-icon"
                          >mdi-close</v-icon
                        >
                      </div>
                    </vue-draggable-resizable>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref="imgTemplateInput"
                @change="handleFileChange"
                style="display: none"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="my-2">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            width="100"
            class="text-capitalize mr-3"
            @click="CancelEditDialog()"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="text-capitalize"
            width="100"
            color="secondary"
            :disabled="disabledUpdateBtn"
            @click="UpdateCertiPositions()"
          >
            {{ $t("update") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLoading" persistent width="300">
      <v-card color="#4caf50" dark class="py-1">
        <v-card-text class="white--text">
          {{ $t("loadingpleasewait") }}
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as moment from "moment/moment";
import axios from "axios";
import certificateJS from "./certificate.js";

export default {
  components: {
    draggable,
    //FreeTransform,
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: null,
    },
    editData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showEditDialog() {
      return this.show_dialog;
    },
    CertificateTemp() {
      return this.editData?.certiData?.plainTemplate || null;
    },
    certificateObj() {
      return this.editData?.certiData;
    },
  },

  data: () => ({
    lastValidText: null,
    disabledUpdateBtn: false,
    imageWidth: 0,
    imageHeight: 0,
    showLoading: false,
    imgContainerWidth: 0,
    imgContainerHeight: 0,
    templateObj: {
      image: null,
      pdf: null,
    },
    aspectRatio: 0,
    todayDate: moment(new Date().toISOString().substr(0, 10))
      .local()
      .format("DD MMMM YYYY"),
    droppedTextField: false,
    clickedTextFieldData: {},
    selectedElement: {},
    //....end of it
    tempElementID: 0,
    imgOriginalHeight: 0,
    imgOriginalWidth: 0,
    colorsForBorder: [
      "rgba(1, 42, 74, 1)",
      "rgba(42, 111, 151, 1)",
      "rgba(87, 204, 153, 1)",
      "rgba(79, 119, 45, 1)",
      "rgba(146, 108, 21, 1)",
      "rgba(242, 149, 89, 1)",
      "rgba(214, 64, 69, 1)",
      "rgba(90, 24, 154, 1)",
    ],
    currentColorSettings: null,
    dragX: 0,
    dragY: 0,
    offsetX: 0,
    offsetY: 0,
    //............................
    selectedFontColor: "#000000",
    colorMenu: false,
    fontBoldItalicUnderline: [],
    fontSizesList: ["12", "14", "16", "18", "20", "22", "24", "26", "28", "30"],
    selectedFontStyle: null,
    selectedFontSize: null,
    fontStylesList: [
      "Arial",
      "Bauhaus 93",
      "Cooper",
      "Dubai",
      "Edwardian Script ITC",
      "Forte",
      "Kanit",
      "Sarabun",
      "Chakra Petch",
      "Sriracha",
      "Mali",
    ],
    Fields: [
      //   {
      //     text: "Image",
      //     type: "stamp_Img",
      //     width: "20",
      //     icon: require("@/assets/images/Stamp.svg"),
      //     disabled: false,
      //   },
      {
        text: "fullnamesm",
        type: "full-name",
        width: "24",
        icon: require("@/assets/images/Pen.svg"),
        disabled: false,
        placeholder: "enter-text",
      },
      {
        text: "textbox",
        type: "text",
        width: "24",
        icon: require("@/assets/images/text_A.svg"),
        disabled: false,
        placeholder: "enter-text",
      },
      //   {
      //     text: "Date stamp",
      //     type: "date",
      //     width: "20",
      //     icon: require("@/assets/images/calendar.svg"),
      //     disabled: false,
      //   },
      //   {
      //     text: "Course name",
      //     type: "course-name",
      //     width: "24",
      //     icon: require("@/assets/images/pen_2.svg"),
      //     disabled: true,
      //   },
    ],
    elements: [],
    dragType: null,
    dragIcon: null,
    draggedItem: {},
  }),

  watch: {
    selectedFontStyle(newVal) {
      let that = this;
      if (that.selectedElement?.type != "stamp_Img" && newVal != null) {
        that.elements = that.elements.map((element) => {
          if (element.id === that.selectedElement.id) {
            element.fontStyle = newVal != null ? newVal : element.fontStyle;
          }
          return element;
        });
      }
    },

    selectedFontSize(newVal) {
      let that = this;
      if (that.selectedElement.type != "stamp_Img" && newVal != null) {
        that.elements = that.elements.map((element) => {
          if (element.id === that.selectedElement.id) {
            element.fontSize = newVal != null ? newVal : element.fontSize;
          }

          return element;
        });
      }
    },

    fontBoldItalicUnderline(newVal) {
      let that = this;
      if (that.selectedElement.type != "stamp_Img") {
        that.elements = that.elements.map((element) => {
          if (element.id === that.selectedElement.id) {
            element.fontBoldItalicUnderline = newVal;
          }
          return element;
        });
      }
    },

    selectedFontColor(newVal) {
      let that = this;
      if (that.selectedElement.type != "stamp_Img") {
        that.elements = that.elements.map((element) => {
          if (element.id === that.selectedElement.id) {
            element.fontColor = newVal != "" ? newVal : element.fontColor;
          }
          return element;
        });
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.certificateImg?.complete) {
        this.onImageLoad();
      }
    });
  },

  methods: {
    change_to_Percentage(item_val, container_val) {
      let result = (item_val / container_val) * 100;
      return result;
    },

    reverse_from_Percentage(item_val, container_val) {
      let result = (item_val / 100) * container_val;
      return result;
    },

    onImageLoad() {
      this.$nextTick(() => {
        const img = this.$refs.certificateImg;
        this.imageWidth = img.clientWidth;
        this.imageHeight = img.clientHeight;

        let temp_ele = this.certificateObj?.certificatePositions;
        this.Fields[0].placeholder = this.editData?.fullName;
        let full_Name = this.editData?.fullName;

        if (full_Name && full_Name.length > 50) {
          full_Name = full_Name.slice(0, 50);
        }

        if (temp_ele && temp_ele.length != 0) {
          this.elements = temp_ele.map((el, i) => {
            const isFullName = el.type === "full-name" ? true : false;
            return {
              ...el,
              id: i + 1,
              certificateID: el.certificateID,
              x: this.reverse_Percentage_X(
                el.x,
                this.imageWidth,
                isFullName,
                el.fontSize,
                full_Name
              ),
              y: this.reverse_from_Percentage(el.y, this.imageHeight),
              width: this.reverse_Percentage_W(
                el.width,
                this.imageWidth,
                isFullName,
                el.fontSize,
                full_Name
              ),
              height: this.reverse_from_Percentage(el.height, this.imageHeight),
              angle: el.angle,
              text: el.text,
              type: el.type,
              icon: el.icon,
              imgUrl: el.imgUrl,
              img_blob: null, // only use in save img
              showText:
                el.type === "full-name"
                  ? this.editData?.fullName || "fullnamesm"
                  : el.inputText,
              //text edit ....
              fontStyle: el.fontStyle,
              fontSize: el.fontSize,
              fontColor: el.fontColor,
              fontBoldItalicUnderline: el.fontBoldItalicUnderline,
              prevent_autoDeactivate: false,
              editing: false,
              showTooltip: true,
              active: false,
            };
          });
          this.tempElementID = temp_ele.length + 1;
        }
      });
    },

    reverse_Percentage_X(
      item_val,
      container_val,
      isFullName,
      fontSize,
      nameText
    ) {
      let result = 0;
      let percent = nameText.length > 30 ? 4 : 15;

      let baseX = (item_val / 100) * container_val;

      if (isFullName && nameText.length > 15) {
        let textLength = nameText.length;
        let originalWidth = baseX;
        let newWidth = originalWidth + (fontSize / percent) * textLength;

        let shift = (newWidth - originalWidth) / 2;

        result = baseX - shift;
      } else {
        result = baseX;
      }

      return result;
    },

    reverse_Percentage_W(
      item_val,
      container_val,
      isFullName,
      fontSize,
      nameText
    ) {
      let result = 0;
      let percent = nameText.length > 30 ? 4 : 15;

      let baseWidth = (item_val / 100) * container_val;

      // Handle the full name case
      if (isFullName && nameText.length > 15) {
        let textLength = nameText.length;
        let additionalWidth = (fontSize / percent) * textLength;
        result = baseWidth + additionalWidth;
      } else {
        // Just calculate the width from percentage for non-full-name cases
        result = baseWidth;
      }

      return result;
    },

    clearActiveBeforeCapture() {
      this.elements = this.elements.map((element) => ({
        ...element,
        //showText: element.type === "full-name" && element.showText === "Full Name" ? "" : element.showText,
        active: false,
        editing: false,
      }));
      this.selectedElement = {};
    },

    async UpdateCertiPositions() {
      let that = this;
      that.disabledUpdateBtn = true;
      that.showLoading = true;

      try {
        await Promise.all([
          that.clearActiveBeforeCapture(),
          that.captureAsImage(),
          that.captureAsPDF(),
        ]);

        const img = this.$refs.certificateImg;
        this.imageWidth = img.clientWidth;
        this.imageHeight = img.clientHeight;

        const positionItem = that.elements.map((v) => ({
          certificateID: that.certificateObj.certificateID, // v.certificateID != 0 ? v.certificateID : 0,
          x: that.change_to_Percentage(v.x, this.imageWidth),
          y: that.change_to_Percentage(v.y, this.imageHeight),
          width: that.change_to_Percentage(v.width, this.imageWidth),
          height: that.change_to_Percentage(v.height, this.imageHeight),
          angle: 0,
          type: v.type,
          text: v.text,
          icon: v.icon,
          inputText: v.showText,
          imgUrl:
            v.type == "stamp_Img"
              ? certificateJS.removeToken(v.imgUrl)
              : v.imgUrl, // img_blob,
          fontStyle: v.fontStyle,
          fontSize: v.fontSize ? v.fontSize : "16",
          fontColor: v.fontColor,
          fontBoldItalicUnderline: v.fontBoldItalicUnderline,
          //new variable.....
          certificatePerOneID: that.certificateObj.certificatePerOneID || 0,
        }));

        that.certificateObj.certificatePositions = positionItem;
        that.certificateObj.templateImage = that.templateObj.image;
        that.certificateObj.templatePDF = that.templateObj.pdf;

        //add certificate data....
        that.updateEditedCertificate();
      } catch (error) {
        that.disabledUpdateBtn = false;
        that.showLoading = false;
        console.error(error);
      }
    },

    async updateEditedCertificate() {
      let that = this;
      let temp = certificateJS.removeToken(that.certificateObj.plainTemplate);

      try {
        let request = {
          certificatePerUserID: that.certificateObj.certificatePerUserID || 0,
          certificateID: that.certificateObj.certificateID,
          templateImage: that.certificateObj.templateImage,
          templatePDF: that.certificateObj.templatePDF,
          quizID: that.certificateObj.quizID,
          courseID: that.certificateObj.courseID,
          certTemplateMasterID: that.certificateObj.certTemplateMasterID,
          createBy: that.editData.createdBy,
          userID: that.editData.userID,
          plainTemplate: temp, // that.certificateObj.plainTemplate, // bg template...
          certificatePositions: that.certificateObj.certificatePositions,
          width: this.imageWidth, //current bg width
          height: this.imageHeight, //current bg height
        };
        console.log("update gruducate certificate: ", request);

        await axios
          .post(
            `${that.web_url}Certificate/AddorUpdateCertificatePerUser`,
            request
          )
          .then(function (res) {
            that.disabledUpdateBtn = false;
            if (res.data.status == 0) {
              that.showLoading = false;
              that.CancelEditDialog();
            }
          });
      } catch (error) {
        console.error(error);
      }
    },

    CancelEditDialog() {
      this.elements = [];
      this.imageWidth = 0;
      this.imageHeight = 0;
      this.disabledUpdateBtn = false;
      this.$emit("close_dialog");
    },

    // save certificate as image and pdf file
    async captureAsImage() {
      const container = document.getElementById("pdf-container");

      // Check if the background image is loaded
      const images = container.querySelectorAll("img");
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = reject;
          }
        });
      });

      try {
        await Promise.all(promises);

        // Temporarily set the container's width and height to its scroll size
        const originalWidth = container.style.width;
        const originalHeight = container.style.height;
        container.style.width = `${container.scrollWidth}px`;
        container.style.height = `${container.scrollHeight}px`;

        const canvas = await html2canvas(container, {
          allowTaint: true,
          useCORS: true,
          scale: 2, // Increase the scale for better resolution
          width: container.scrollWidth,
          height: container.scrollHeight,
        });

        // Restore the container's original size
        container.style.width = originalWidth;
        container.style.height = originalHeight;

        const dataUrl = canvas.toDataURL("image/jpeg", 0.7);

        let imagedata = "";
        await axios
          .post(`${this.web_url}File/UploadFileStringBase`, {
            base64: dataUrl,
            fileName: "certificate.png",
          })
          .then(function (base64Res) {
            if (base64Res.data) {
              imagedata = base64Res.data;
            }
          });
        this.templateObj.image = imagedata;

        // const imageElement = document.createElement("img");
        // imageElement.src = dataUrl;
        // document.body.appendChild(imageElement); // Append the image to the body to view it (optional)
        // const link = document.createElement("a");
        // link.href = dataUrl;
        // link.download = "pdf-container.png";
        // link.click();
      } catch (error) {
        console.error("Error capturing image:", error);
      }
    },

    async captureAsPDF() {
      const container = document.getElementById("pdf-container");

      // Check if the background image is loaded
      const images = container.querySelectorAll("img");
      const promises = Array.from(images).map((img) => {
        return new Promise((resolve, reject) => {
          if (img.complete) {
            resolve();
          } else {
            img.onload = resolve;
            img.onerror = reject;
          }
        });
      });

      try {
        await Promise.all(promises);

        // Temporarily set the container's width and height to its scroll size
        const originalWidth = container.style.width;
        const originalHeight = container.style.height;
        container.style.width = `${container.scrollWidth}px`;
        container.style.height = `${container.scrollHeight}px`;

        const canvas = await html2canvas(container, {
          allowTaint: true,
          useCORS: true,
          scale: 2, // Increase the scale for better resolution
          width: container.scrollWidth,
          height: container.scrollHeight,
        });

        // Restore the container's original size
        container.style.width = originalWidth;
        container.style.height = originalHeight;

        // Get the canvas dimensions
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        // Determine PDF orientation
        const orientation =
          canvasWidth > canvasHeight ? "landscape" : "portrait";

        // Create a PDF with dimensions matching the canvas
        const pdf = new jsPDF({
          orientation: orientation,
          unit: "px",
          format: [canvasWidth, canvasHeight],
        });

        pdf.addImage(
          canvas.toDataURL("image/jpeg", 0.7),
          "JPEG",
          0,
          0,
          canvasWidth,
          canvasHeight,
          undefined,
          "FAST"
        );

        //Save the PDF with a specified name
        // const pdfFileName = "Certificate.pdf";
        // pdf.save(pdfFileName);

        const pdfBlob = pdf.output("blob");
        let formData = new FormData();
        formData.append("file", pdfBlob, "Certificate.pdf");
        let pdfdata = "";
        await axios
          .post(`${this.web_url}File/UploadPDFFile`, formData)
          .then(function (response) {
            if (response.data.status === 0) {
              pdfdata = response.data.data;
            }
          });
        this.templateObj.pdf = pdfdata;
      } catch (error) {
        console.error("Error capturing PDF:", error);
      }
    },

    async uploadImageFile(imgFile, id) {
      if (!imgFile) return "";

      try {
        const response = await axios.post(
          `${this.web_url}File/UploadFileStringBase`,
          {
            base64: imgFile,
            fileName: `image_${id}.png`, //"Certificate.png",
          }
        );

        return response.data || "";
      } catch (error) {
        console.error("Error uploading image: ", error);
        return "";
      }
    },
    // .....end of image and pdf file generate

    showTooltip(element) {
      element.showTooltip = true;
    },

    hideTooltip(element) {
      element.showTooltip = false;
    },

    dblClick(element) {
      //this.clearActive(this.selectedElement)

      this.elements = this.elements.map((e) => {
        e.editing = e.id === element.id;
        return e;
      });
      this.lastValidText =
        element.type === "full-name" ? element.showText : null;

      this.droppedTextField = element.type != "stamp_Img";
      this.selectedFontColor = this.droppedTextField ? "#000000" : null;
      //if text elemetn has already text edited data
      if (this.droppedTextField) {
        this.selectedFontColor = element.fontColor; // != "" ? element.fontColor : "#000000";
        this.selectedFontStyle = element.fontStyle; // != null ? element.fontStyle : "";
        this.selectedFontSize = element.fontSize; // != null ? element.fontSize : null;
        this.fontBoldItalicUnderline = element.fontBoldItalicUnderline;
      }
    },

    // vue-draggable-resizable methods.....
    onDragstop(left, top) {
      let id = this.selectedElement.id;

      this.updateElement(id, { x: left, y: top });
    },

    onDragging(left, top) {
      let id = this.selectedElement.id;
      this.updateElement(id, { x: left, y: top });
    },

    onResizing(x, y, w, h) {
      let id = this.selectedElement.id;
      this.updateElement(id, { x: x, y: y, width: w, height: h });
    },

    onResizstop(x, y, w, h) {
      let id = this.selectedElement.id;
      this.updateElement(id, { x: x, y: y, width: w, height: h });
    },

    onActivated(element) {
      this.setActive(element.id);
      this.selectedElement = element;      
    },
    onDeactivated(ele) {
      if (ele.type === "stamp_Img") {
        this.clearActive(this.selectedElement);
      } else {
        if (!ele.editing) this.clearActive(ele);
      }
    },

    updateElement(id, data) {
      this.elements = this.elements.map((element) => {
        if (element.id === id) {
          return { ...element, ...data };
        }
        return element;
      });
    },
    setActive(id) {
      this.elements = this.elements.map((element) => {
        element.active = element.id === id;
        return element;
      });
    },
    clearActive(selected_ele) {
      this.elements = this.elements.map((element) => {
        element.active = !selected_ele.id === element.id; //false
        return element;
      });
      this.selectedElement = {};
    },
    removeElement(id) {
      this.elements = this.elements.filter((element) => element.id !== id);
      this.selectedElement = {};
      this.droppedTextField = false;
    },
    getElementStyles(element) {
      return {
        border: element.active ? "2px solid #A6CC39" : "transparent",
        // transform: `rotate(${element.angle || 0}deg)`,
        cursor: element.active ? "move" : "default",
      };
    },
    //....vue-draggable-resizable end of it........................

    // Update text only when user stops editing (on blur)
    updateItem(index, event) {
      this.elements[index].showText = event.target.innerText;
    },

    limitTextLength(event, type) {
      const text = event.target.innerText;
      if (text.length > 49 && type === "full-name") {
        alert("Username can not be longer than 50 characters!!");
        // Revert to the last valid text to prevent update
        event.target.innerText = this.lastValidText;
        this.placeCaretAtEnd(event.target); // Keep caret at the end
      } else {
        this.lastValidText = text;
        this.placeCaretAtEnd(event.target); // Keep caret at the en
      }
    },

    // Helper method to keep the caret at the end of contenteditable element
    placeCaretAtEnd(el) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(el);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    },

    // updateText(event, element) {
    //   this.elements.find((el) => {
    //     if (el.id === element.id) {
    //       el.showText = event.target.innerText;
    //     }
    //     return el;
    //   });
    //   this.selectedElement.showText = event.target.innerText;
    // },

    saveEditedText() {
      let that = this;
      that.elements = that.elements.map((element) => {
        if (element.id === that.selectedElement.id) {
          element.showText = that.selectedElement.showText;
          element.fontStyle = that.selectedFontStyle;
          element.fontSize = that.selectedFontSize;
          element.fontColor = that.selectedFontColor;
          element.fontBoldItalicUnderline = that.fontBoldItalicUnderline;

          element.editing = false;
          element.active = !element.id === that.selectedElement.id; //false
          element.prevent_autoDeactivate =
            !element.id === that.selectedElement.id; //false
        }
        this.lastValidText = null;

        return element;
      });

      that.selectedFontStyle = null;
      that.selectedFontSize = null;
      that.fontBoldItalicUnderline = [];
      that.selectedFontColor = "#000000";
      that.selectedElement = {};
      that.droppedTextField = false;
    },

    setImgAspectRatio() {
      const img = new Image();
      img.src = this.CertificateTemp;
      img.onload = () => {
        this.imgOriginalHeight = img.height;
        this.imgOriginalWidth = img.width;
      };
    },

    startDrag(item) {
      this.dragType = item.type;
      this.dragIcon = true;
      this.draggedItem = item;
    },

    dragMovement(event) {
      const container = document.getElementById("pdf-container");
      const offset = container.getBoundingClientRect();

      // Calculate the correct x and y positions
      this.dragX = event.clientX - offset.left;
      this.dragY = event.clientY - offset.top;

      // Ensure the coordinates stay within the container bounds
      this.dragX = Math.max(0, Math.min(this.dragX, offset.width));
      this.dragY = Math.max(0, Math.min(this.dragY, offset.height));
    },

    handleDrop() {
      // drop item in drop zone

      switch (this.dragType) {
        case "stamp_Img":
          this.$refs.imgTemplateInput.click();
          this.droppedTextField = false;
          break;
        case "text":
          this.pushElementInDropZone(this.dragType, null);
          break;
        case "full-name":
          this.pushElementInDropZone(this.dragType, null);
          break;
        case "course-name":
          this.pushElementInDropZone(this.dragType, null);
          break;
        case "date":
          this.pushElementInDropZone(this.dragType, null, null);
      }
    },

    handleFileChange(evt) {
      const file = evt.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(evt.target.value)) {
        alert("Please choose image!");
        return false;
      }
      if (file && this.dragType === "stamp_Img") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageSrc = e.target.result;
          this.pushElementInDropZone("stamp_Img", imageSrc);
        };
        reader.readAsDataURL(file);
      }
    },

    pushElementInDropZone(file_type, img_src) {
      // text, type, icon
      let type = this.draggedItem.type;
      let text = this.draggedItem.text;
      let temp_data = this.draggedItem.placeholder;

      let temp = {
        certificateID: 0,
        id: this.tempElementID + 1,
        x: this.dragX,
        y: this.dragY,
        width: type === "stamp_Img" ? 100 : 300,
        height: type === "stamp_Img" ? 100 : 40,
        angle: 0,
        text: text,
        imgUrl: img_src, //icon
        type: file_type,
        showText: type == "date" ? this.todayDate : temp_data, // data: null
        active: true,
        img_blob: null, //only use in save img
        // text edit part....
        fontStyle: null,
        fontSize: null,
        fontColor: "#000000",
        fontBoldItalicUnderline: [],
        prevent_autoDeactivate: false, //type != "stamp_Img",
        editing: false,
        showTooltip: true,
      };
      this.elements.push(temp);
      this.tempElementID = this.tempElementID + 1;
      this.dragType = null;
    },

    //get color for color picker
    async GetListColor() {
      let self = this;
      if (self.ColorAssigned == 0) {
        var ColorArray = [];

        var j = 0;
        for (var i = 0; i < self.ListDataSelectsigner.length; i++) {
          if (j < self.colors.length) {
            ColorArray.push(self.colors[j]);
            j++;
          } else {
            j = 0;
            ColorArray.push(self.colors[j]);
            j++;
          }
        }
        let ColorMap = new Array();
        let c = 0;

        for (var item in self.ListDataSelectsigner) {
          ColorMap.push({
            color: ColorArray[c],
            tWorkflowID: self.ListDataSelectsigner[item],
          });
          c++;
        }
        self.currentColorSettings = ColorMap;
      }
      self.ColorAssigned = 1;
    },
  },
};
</script>

<style scoped>
.sticky-sidebar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
::v-deep .v-dialog > .v-card > .v-card__title {
  padding: 16px 16px 10px;
}
::v-deep .v-dialog > .v-card > .v-card__text {
  padding: 0 16px 10px !important;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100%;
  padding: 2rem 1.5rem;
}

.responsive-img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
::v-deep .v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 40px;
  border-color: #8e8e8e80 !important;
}

::v-deep .v-list-btn-bg {
  background-color: #f5f5f5 !important;
  margin-top: 10px !important;
  border-radius: 3px;
  height: 36px;
  color: #8e8e8e !important;
  border: 1px solid #ccc;
}

::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  border-color: #707070;
  border-radius: 3px;
}

::v-deep .primary--text {
  color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}

::v-deep .primary {
  background-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-item:not(:last-child) {
  margin-bottom: 12px;
}
</style>

<!-- new vue-draggable-resizable -->
<style scoped>
.vdr {
  touch-action: none;
  border: unset;
}
.element-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}
.image-element {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.mdi-close-icon {
  position: absolute;
  top: -20px;
  right: -25px;
  background: red;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  font-size: 15px; /* This ensures the icon does not resize */
  padding: 2px;
}
.text-element {
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltip {
  position: absolute;
  bottom: -7px; /* Adjust based on your needs */
  left: 0%;
  transform: translateY(100%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  width: inherit;
  display: flex;
  text-wrap: wrap;
}

.tooltip-text-element {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-text-element:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
::v-deep .handle {
  width: 10px;
  height: 10px;
  background: #a6cc39 !important;
  border: 1px solid #a6cc39;
  border-radius: 50%;
}
::v-deep .handle-br {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}
::v-deep .handle-tr {
  top: -5px;
  right: -5px;
  cursor: se-resize;
}
::v-deep .handle-bl {
  bottom: -5px;
  left: -5px;
  cursor: se-resize;
}
::v-deep .handle-tl {
  top: -5px;
  left: -5px;
  cursor: se-resize;
}
::v-deep .v-list-item--disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
